.cardmodal {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cardmodal button {
  background-color: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  color: black;
}

.cardmodal button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}

.product-fields {
  margin-top: 20px;
  padding: 20px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-row div {
  width: calc(50% - 10px); /* Adjusted width to accommodate two fields */
}

.input-row label {
  margin-bottom: 5px;
}

.input-row input {
  width: 100%;
  padding: 8px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  height: 50%;
}

.input-row input,
.input-row select,
.input-row textarea {
  width: 100%;
  padding: 8px;
  border: none;
  background-color: #f0f0f0;
}

.css-b62m3t-container {
  width: 50% !important;
  background-color: #f0f0f0;
}

.select-field select {
  width: 100%;
  height: 47px;
  padding: 0px 0px 0px 20px;
  border-radius: 14px;
  box-shadow: 0px 0px 5px 2px rgba(12, 10, 11, 0.25);
  border: none;
  color: black;
  outline: none;
  align-items: center;
}
/*Email Modal*/
.email-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Additional styling */
  width: 400px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it's above other content */
  padding: 20px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .input-row {
    flex-direction: column;
    margin-bottom: 10px; /* Reduce space between rows for smaller screens */
  }

  .input-row div {
    width: 100%;
    margin-bottom: 10px;
  }
}
.dropselect {
  width: 107%;
  height: 80%;
}

body {
  font-family: "Nunito", sans-serif; /* Apply Nunito font to the entire document */
}
.finance-page {
  padding: 21px;
}
.search-field-fin {
  display: flex;
  align-items: center;
  margin-left: 200px;
}

.search-input-fin {
  padding: 10px 30px 10px 40px; /* Adjust as needed */
  background-image: url("../../../assets/product/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 140px;
}

body {
  font-family: "Nunito", sans-serif; /* Apply Nunito font to the entire document */
}
.subscriber-page {
  padding: 20px;
  background: rgba(250, 251, 255, 1);
}
.search-field2 {
  display: flex;
  align-items: center;
  margin-left: 200px;
}

.search-input2 {
  padding: 10px 30px 10px 40px; /* Adjust as needed */
  background-image: url("../../../assets/product/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cardmodal-sub {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}
.cardmodal-sub button {
  background-color: transparent;
  border: none;
  margin: 3px;
  cursor: pointer;
  color: black;
}

.cardmodal-sub button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}

.column {
  float: left;
  width: 50%;
}
.mobile {
  margin-top: 3px;
}
.balance {
  margin-top: 3px;
}

.card-container1 {
  display: flex;
  flex-wrap: wrap;
}

.card1 {
  width: calc(15% - 0px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: white;
  padding: 20px;
  justify-content: space-around;
  overflow: hidden;
}
.card1 .three-dot-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
}
.selected-card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid purple;
}
.subscriber-name {
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.subscriber-logo {
  width: 35%;
  height: 32%;
  border-radius: 38px;
}
.options-icon {
  position: absolute;
  margin-top: 10px;
  margin-left: 20px;
}
.dot-icon {
  display: block;
  margin: 0 auto; /* This will horizontally center the dot */
}
.row {
  display: flex;
}

.column {
  flex: 1;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
.cal-btn {
  text-align: center;
  margin-top: 30px;
}
.row .column:first-child.visible {
  flex: 0 0 70%; /* 60% width for the first column */
}

.row .column:last-child.visible {
  flex: 0 0 30%;
  border: 1px solid #555;
  border-right: none;
}

.four-cards-per-row .card1 {
  width: calc(27% - 25px);
  margin-right: 10px;
}

.row-group {
  display: flex;
}

.column-group {
  flex: 1;
}
.card-title {
  position: relative;
  margin-top: 0px;
  margin-bottom: 10px;
}
.session-batch {
  margin: 0;
  text-align: left;
  font-size: 13px;
}
.card-group {
  width: calc(24% - 10px);
  margin-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: center;
  position: relative;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid gray;
  background-color: white;
}

.right-arrow1 {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
.group-img {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Conditionally show 5 cards per row */
.five-cards-per-row .card1 {
  width: calc(14% - 0px);
  margin-right: 18px;
}
.card-container2 {
  display: flex;
  flex-wrap: wrap;
}

.four-cards-per-row .card-group {
  width: calc(18% - -42px);
  padding: 10px;
}

.two-cards-per-row .card-group {
  width: calc(49% - 20px);
  padding: 7px;
}
.column-group:first-child {
  flex-basis: 60%; /* First column takes up 70% of the width */
}

.column-group:last-child {
  flex-basis: 40%; /* Second column takes up 30% of the width */
}
.cardmodal-sub-usermodal {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: max-content;
  margin: auto;
}

.cardmodal-sub-usermodal button {
  background-color: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  color: black;
}

.cardmodal-sub-usermodal button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}

.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 5px); /* Adjust as needed */
  left: 0;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999; /* Ensure the dropdown appears above other elements */
}

.dropdown-content button {
  display: block;
  width: 100%;
  padding: 8px 12px; /* Add padding for button content */
  text-align: left; /* Align text to the left */
  border: none; /* Remove border */
  background: none; /* Remove background */
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #f5f5f5; /* Change background color on hover */
}

/*Accordian*/
.accordion-sub {
  padding-top: 20px;
}
.accordion-sub-btn {
  color: #333;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: white;
  display: flex;
  align-items: center;
}
.accordion-sub-icon {
  margin-right: 10px; /* Add some spacing between icon and text */
}

.accordion-sub-text {
  flex: 1;
  font-size: 15px;
  font-weight: bold;
}
.accordion-sub-content {
  display: none;
  position: relative;
  margin-left: 10px;
}

.accordion-sub-content.active {
  display: block;
}
.acc-sub-content {
  align-items: center; /* Align items vertically */
  justify-content: space-between; /* Distribute space between items */
}
.acc-sub-content1 {
  display: flex;
  justify-content: space-between;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.collect-btn {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.collect-btn:hover {
  background-color: #45a049;
}

.acc-sub-content h4 + p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-right: 10px; /* Adjust the margin as needed */
}
.acc-sub-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-right: 10px;
  font-size: 12px;
}
.acc-sub-content button {
  margin-left: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  height: 30px;
  margin-top: -20px;
  width: 100px;
}
.acc-sub-content1 {
  display: flex;
}
.accordion-sub-arrow {
  top: 0;
  right: 0;
}
.mem-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  background: linear-gradient(90deg, #9e40dc 0%, #c35452 100%);
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.card-header h4 {
  margin: 0;
  font-size: 18px;
  color: #333333;
}

.close-btn {
  cursor: pointer;
}

.card-body {
  padding: 1px;
}

.membership-info {
  display: flex;
}

.membership-left,
.membership-right {
  flex: 1;
}

.membership-left {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

.membership-right {
  padding-left: 10px;
}
.membership-info p {
  font-size: 13px;
}

.lead-card {
  border-radius: 10px;
  padding: 20px;
  background-color: white;
}

/* Attendance*/
.attendance-container {
  display: flex;
  justify-content: space-between;
}

.attendance-column {
  width: 60%; /* Width of the first column */
}

.attendance-column:nth-child(2) {
  width: 35%; /* Width of the second column */
}

/* Example styling for column content */
.attendance-column p {
  margin-bottom: 10px;
  /* Add any other styles you need */
}

.user-modal1 {
  padding: 10px;
  position: relative;
  background-color: white;
  border-radius: 10px;
}
.closebtn {
  background: none;
  border: none;
  cursor: pointer;
}
.modal-header-sub {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.modal-content-sub {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px; /* Optional: Set maximum width for the modal */
  width: 90%;
  max-height: 90%; /* Set maximum height for the modal */
  overflow-y: auto;
  position: relative;
}

.closebtn {
  background: none;
  border: none;
  cursor: pointer;
}

.deletebtn-container {
  margin-left: auto;
}

.deletebtn {
  background: none;
  border: none;
  cursor: pointer;
}
.avatar-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
}
.user-details {
  display: flex;
  flex-direction: column;
}

.user-details > div {
  display: flex;
  align-items: center;
  padding: 10px;
}

.user-details > div img {
  margin-right: 5px;
}

.user-details > div p {
  margin: 0;
}
.line2 {
  width: 100%;
  margin: 5px 0;
}

.accordion-sub-card {
  margin-bottom: 10px; /* Adjust spacing between cards */
  border: 1px solid #ccc; /* Add a border around each card */
  border-radius: 5px; /* Add rounded corners */
  overflow: hidden; /* Hide overflow content */
}
.members-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.member-card {
  width: calc(47% - 10px); /* Adjust width for two cards per row with gap */
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center;
  padding: 5px;
}
.task-card .row {
  display: flex;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
}

.task-card .column {
  flex: 1; /* Make both columns grow to fill available space */
  padding: 8px; /* Add padding for spacing */
}

.product-card {
  border: 1px solid #ccc; /* Add border for the card */
  padding: 10px;
  border-radius: 7px;
  margin-top: 10px;
}

.row {
  display: flex; /* Use flexbox to arrange columns */
}

.column-20 {
  flex: 20%; /* Set width for the first and third column */
  padding: 10px; /* Add padding to columns */
}

.column-60 {
  flex: 60%; /* Set width for the second column */
  padding: 10px; /* Add padding to columns */
}

/* Styles for the payment modal */
.payment-modal {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
  justify-content: center; /* Center the content vertically */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.payment-modal button.closebtn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.payment-modal select,
.payment-modal input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.payment-modal label {
  margin-bottom: 5px;
}
.payment-modal .submit-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.payment-modal .submit-button:hover {
  background-color: #45a049;
}
.react-calender {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  margin-top: 45px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .attendance-container {
    flex-direction: column; /* Switch to row layout for mobile */
  }
  .search-field2 {
    margin-left: 0; /* Adjust margin for smaller screens */
  }
  .four-cards-per-row .card1,
  .five-cards-per-row .card1 {
    width: calc(100% - 20px); /* Make each card occupy full width */
    margin-bottom: 20px; /* Add margin between cards */
  }
  .card1 {
    width: calc(100% - 20px); /* Make each card occupy full width */
    margin-bottom: 20px; /* Add margin between cards */
  }

  .row .column:first-child.visible {
    flex: 0 0 100%; /* Full width for the first column */
  }

  .row .column:last-child.visible {
    position: absolute; /* Position absolutely within the parent container */
    top: 0; /* Align with the top of the parent container */
    left: calc(33.33% + 10px); /* Position to the right of the first column */
    width: calc(66.66% - 20px); /* Occupy remaining width */
    z-index: 1; /* Ensure it's above the first column */
    border: 1px solid #555; /* Add border */
    border-left: none; /* Remove left border */
    border-radius: 0 5px 5px 0; /* Adjust border radius */
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .attendance-container {
    flex-direction: column; /* Switch to row layout for mobile */
  }
  .search-field2 {
    margin-left: 0; /* Adjust margin for smaller screens */
  }
  .four-cards-per-row .card1,
  .five-cards-per-row .card1 {
    width: calc(50% - 60px); /* Make each card occupy full width */
    margin-bottom: 20px; /* Add margin between cards */
  }
  .card1 {
    width: calc(100% - 20px); /* Make each card occupy full width */
    margin-bottom: 20px; /* Add margin between cards */
  }

  .row .column:first-child.visible {
    flex: 0 0 100%; /* Full width for the first column */
  }
  .row .column:last-child.visible {
    position: absolute; /* Position absolutely within the parent container */
    top: 0; /* Align with the top of the parent container */
    left: calc(33.33% + 10px); /* Position to the right of the first column */
    width: calc(66.66% - 20px); /* Occupy remaining width */
    z-index: 1; /* Ensure it's above the first column */
    border: 1px solid #555; /* Add border */
    border-left: none; /* Remove left border */
    border-radius: 0 5px 5px 0; /* Adjust border radius */
  }
}

/* For screens between 768px and 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-field2 {
    margin-left: 100px; /* Adjust margin for medium-sized screens */
  }
  .four-cards-per-row .card1,
  .five-cards-per-row .card1 {
    width: calc(50% - 175px); /* Make each card occupy full width */
    margin-bottom: 20px; /* Add margin between cards */
  }
  .card1 {
    width: calc(33.33% - 20px); /* Adjust card width for medium-sized screens */
  }
  .row .column:last-child.visible {
    position: absolute; /* Position absolutely within the parent container */
    top: 0; /* Align with the top of the parent container */
    left: calc(33.33% + 10px); /* Position to the right of the first column */
    width: calc(66.66% - 20px); /* Occupy remaining width */
    z-index: 1; /* Ensure it's above the first column */
    border: 1px solid #555; /* Add border */
    border-left: none; /* Remove left border */
    border-radius: 0 5px 5px 0; /* Adjust border radius */
  }
}

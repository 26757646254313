/* Sidebar.css */
.sidebar {
  width: 250px; /* Width when open */
  min-width: 50px; /* Minimum width to accommodate icons when closed */
  height: 100vh;
  background-color: white;
  color: white;
  transition: width 0.3s ease;
  overflow-x: unset;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.sidebar.open {
  width: 250px; /* Width when open */
}

.sidebar.closed {
  width: 60px; /* Width when closed */
}

.sidebar-toggle {
  position: absolute; /* Position toggle button absolutely */
  top: 0;
  right: -22px;
  display: flex;
  align-items: center; /* Center vertically */
  padding: 10px;
  z-index: 1000;
}

.sidebar-toggle img,
.sidebar-toggle svg {
  width: 24px; /* Set width to match button icon */
  height: 38px; /* Set height to match button icon */
  cursor: pointer;
  color: #fff;
}

.sidebar-menu {
  padding-bottom: 40px; /* Space for toggle button */
  position: relative;
  padding: 5px;
}

.sidebar-menu li {
  padding: 5px;
  display: flex;
  align-items: center;
}

.sidebar-menu li span {
  margin-left: 10px;
}

.menu-button {
  display: flex;
  align-items: center;
  width: 100%; /* Set width to 100% */
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  border-radius: 7px;
  padding-left: 3px;
  text-decoration: none;
}
.menu-button img,
.menu-button svg {
  margin-left: 5px; /* Add margin to the left */
}

.menu-button span {
  margin-left: 10px;
}
.arrow-icon {
  width: 20px; /* Adjust arrow icon size as needed */
}

.sidebar-header {
  display: flex;
  justify-content: space-between; /* Align items to the start and end */
  position: relative; /* Ensure relative positioning */
}
.header-content {
  display: flex;
  align-items: center;
}

.sidebar-heading {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.right-arrow {
  position: absolute;
  right: 10px; /* Adjust the distance from the right */
  transform: translateY(-50%);
}

/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-header h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.card-body {
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}
.notification-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
body {
  font-family: "Nunito", sans-serif;
}

.task-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.task-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 40%;
  max-width: 90%;
  max-height: 80vh; /* Adjust the max-height as needed */
  overflow-y: auto;
}

.close-button-task {
  position: relative;
  top: 0px;
  left: 95%;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 20px;
}

h2 {
  margin-bottom: 20px;
}

.input-row {
  margin-bottom: 15px;
}

.input-row div {
  margin-bottom: 10px;
}

.input-row label {
  display: block;
  margin-bottom: 5px;
}

.input-row input {
  width: calc(100% - 10px);
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-row {
  display: flex;
  justify-content: flex-end;
}

.submit-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.save-button {
  padding: 8px 16px;
  background: linear-gradient(
    to bottom,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 65%;
  margin-top: 20px;
  cursor: pointer;
}
.outlined-input {
  background: rgba(247, 247, 248, 1);
  margin-bottom: 10px;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: calc(95% - 18px);
  height: 43px;
}

.submit-button:hover {
  background-color: #0056b3;
}
.new-card {
  background: linear-gradient(90deg, #9e40dc 0%, #c35452 100%);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.new-card p {
  margin: 0;
}
.content-right {
  width: 100%;
}

.column {
  flex: 1;
}
.row {
  display: flex;
  justify-content: space-around; /* To evenly distribute columns */
}
.column p {
  margin: 8px; /* Remove default margin */
}
.task-columns {
  display: flex;
}

.task-column {
  flex: 1;
}
.card-above-task {
  background-color: #f0f0f0;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.card-above-task .row {
  display: flex;
}

.card-above-task .column {
  flex: 1;
  padding: 10px;
}

.card-above-task h3 {
  color: #333;
  margin-bottom: 5px;
}

.card-above-task p {
  color: #666;
  margin-top: 0;
}
.center {
  display: flex;
  justify-content: center; /* Center button horizontally */
}

.green-bg {
  color: white;
  background-color: green;
  height: 35px;
  width: 121px;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .task-modal-content {
    width: 90%;
  }

  .card-above-task .column {
    padding: 5px;
  }
}

.banner-page {
  padding: 20px;
  background: rgba(250, 251, 255, 1);
}
.dashboard-container {
  display: flex;
  align-items: center;
}

.buttons-container {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.button {
  margin-left: 10px;
}

.dashboard-container h5,
.dashboard-container p {
  margin-right: 5px;
}
.add-button {
  margin-right: 25px;
  background: linear-gradient(
    to bottom,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
.add-button1 {
  margin-right: 25px;
  background: linear-gradient(
    to bottom,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  width: 125px;
}
.export-button {
  margin-right: 25px;
  background-color: rgba(244, 236, 251, 1);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  width: 100px;
}

.date-filter {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 20px;
}

.cardmodal {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.cardmodal button {
  background-color: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  color: black;
}

.cardmodal button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}
.text-with-gradient {
  background: linear-gradient(
    135deg,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  -ms-background-clip: text;
  -ms-text-fill-color: transparent;
  background-clip: text;
}

.search-field-mem {
  display: flex;
  align-items: center;
  margin-left: 170px;
}

.search-input-mem {
  padding: 10px 30px 10px 40px; /* Adjust as needed */
  background-image: url("../../../assets/product/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 250px;
}
.custom-table-ban img {
  vertical-align: middle;
  margin-right: 5px;
}

.custom-table-ban td {
  vertical-align: middle;
  color: rgba(102, 112, 133, 1);
}

.date-input {
  margin-top: 10px;
  margin-right: 10px;
}

.date-input-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
}

.date-input-field::after {
  position: absolute;
  transform: translateY(-50%);
}

.filter-button {
  color: rgb(175, 173, 173);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 36px;
  margin-right: 25px;
}

.filter-icon {
  width: 16px; /* Adjust icon size as needed */
  height: 16px;
  margin-right: 5px; /* Adjust margin between icon and text */
}

/*Table*/
.custom-table-ban {
  width: 100%;
  border-collapse: collapse;
}
.custom-table-ban th,
.custom-table-ban td {
  padding: 8px;
  text-align: center; /* Center align the content */
}

.custom-table-ban th {
  background-color: #f2f2f2;
}

.custom-table-ban tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.custom-table-ban tbody tr:hover {
  background-color: #ddd;
}

.table-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.custom-table-ban {
  width: 100%;
}

.custom-table-ban th,
.custom-table-ban td {
  flex: 1;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.pagination li {
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  text-align: center;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 7px;
}

.pagination li.active {
  background: linear-gradient(
    to bottom,
    rgba(136, 61, 207, 1),
    rgba(69, 31, 105, 1)
  );
  color: #fff;
}
.pagination-and-info {
  display: flex;
  justify-content: space-between;
}
.items-info {
  margin-top: 35px;
  font-size: 14px;
  color: #666;
}

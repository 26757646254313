.main {
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f7f9;
  color: #12344d;
  display: grid;
}
.columns {
  display: grid;
  justify-content: center;
}

.column {
  grid: 1;
}
body {
  font-family: "Nunito", sans-serif;
}

.form-field {
  margin-bottom: 20px;
}

.login-form {
  display: grid;
  padding: 2rem;
  max-width: 600px;
  margin: auto;
}
.welcome-text {
  text-align: center;
}
.container {
  height: 100vh;
  display: grid;
  justify-content: center;
  align-items: center;
}

.form-group {
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 0.5rem;
}

.login-form .form-field:last-child {
  margin-top: 16px; /* Adjust the padding between fields and button */
}

.wide-text-field {
  width: 300px; /* Increase TextField width */
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-grid {
  display: grid;
  grid-template-columns: 69% 31%;
}

.welcome-text {
  grid-column: 1 / span 2;
  text-align: center;
}
.logo {
  max-width: 200px; /* Adjust the max-width as needed */
  max-height: 100px; /* Adjust the max-height as needed */
  margin-bottom: 10px; /* Add margin to separate logo from text */
}

.forgot-password {
  text-align: right;
  margin-top: -15px;
  color: black;
  font-size: 13px;
}
.forgot-password-link {
  text-transform: none;
  text-decoration: none;
  color: black;
}

.login-image {
  position: relative;
  overflow: hidden;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .login-grid {
    grid-template-columns: 1fr;
  }
  .login-image {
    display: none;
  }
}

body {
  font-family: "Nunito", sans-serif; /* Apply Nunito font to the entire document */
}
.settings-page {
  padding: 20px;
}
.settings-container {
  display: flex;
  align-items: center;
}
.cardmodal-settings {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.cardmodal-settings button {
  background-color: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  color: black;
}

.cardmodal-settings button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}
.personal-info-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.info-item {
  border: 1px solid rgba(102, 112, 133, 1);
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #ccc;
}

.userdata {
  margin-top: 5px;
  margin-bottom: 0px;
  color: rgba(102, 112, 133, 1);
  font-size: unset;
}
.userdata-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label {
  min-width: 120px; /* Adjust width as needed */
}

.edit-button {
  margin-left: 10px;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 5%;
}
.container-auth {
  padding: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 15px;
  text-align: center;
}

.custom-table th {
  background-color: #f2f2f2;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.table-container-auth {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
}

.custom-table {
  width: 100%;
}

.custom-table th,
.custom-table td {
  flex: 1;
}

.input-row1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-row1 div {
  width: calc(50% - 10px); /* Adjusted width to accommodate two fields */
}
.input-row1 input {
  padding: 8px;
  border: none;
  background-color: #f0f0f0;
  border-radius: 5px;
  height: 50%;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .settings-page {
    padding: 10px;
  }

  .cardmodal-settings {
    width: 100%;
    margin-bottom: 10px;
  }

  .cardmodal-settings button {
    margin: 5px 0;
  }

  .personal-info-container {
    margin-top: 5px;
  }

  .info-item {
    padding-bottom: 20px;
  }

  .label {
    min-width: 90px; /* Adjust width as needed */
  }

  .edit-button {
    margin-left: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .settings-page {
    padding: 15px;
  }

  .cardmodal-settings {
    width: 100%;
    margin-bottom: 10px;
  }

  .cardmodal-settings button {
    margin: 5px 0;
  }

  .personal-info-container {
    margin-top: 5px;
  }

  .info-item {
    padding-bottom: 30px;
  }

  .label {
    min-width: 110px; /* Adjust width as needed */
  }

  .edit-button {
    margin-left: 5px;
  }
}

.formbtn {
  margin-bottom: 1%;
}

input[type="checkbox"]:checked {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

input[type="checkbox"]:focus-visible {
  outline: none;
  /* Remove the focus outline */
  box-shadow: none;
  /* Remove the box shadow */
}

.table-body-align {
  /* display: flex;
justify-content: center; */
  text-align: -webkit-center;
}

.th_style {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.sticky-table-container {
  position: relative;
  overflow: auto;
  height: 480px; /* Set the desired height for the table container */
}

.sticky-table-header {
  position: sticky;
  top: 0;
  background-color: #fff; /* Set the background color according to your design */
  z-index: 1;
}
.indexInput {
  text-align: center;
}

/* Adjusted styles for search field */
body {
  font-family: "Nunito", sans-serif; /* Apply Nunito font to the entire document */
}
.search-field-team {
  display: flex;
  align-items: center;
  margin-left: 200px;
}

.search-input-team {
  padding: 10px 30px 10px 40px; /* Adjust as needed */
  background-image: url("../../../assets/product/search.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 140px;
}

.four-cards-per-row .card1 {
  width: calc(22% - 25px);
  margin-right: 10px;
}
.three-cards-per-row .card1 {
  width: calc(27% - 25px);
  margin-right: 10px;
}

/* Adjusted styles for card container */
.card-container-team {
  position: unset;
  display: flex;
  flex-wrap: wrap;
}
::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Adjusted styles for card */
.card-team {
  background: rgba(246, 253, 251, 1);
  width: calc(15% - 0px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin-right: 20px;
  border-radius: 10px;
  border: 1px solid gray;
  padding: 20px;
  justify-content: space-around;
  overflow: hidden;
}

.card-team .three-dot-icon {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.show-second-column .card-container-team {
  justify-content: space-around; /* Show 3 cards per row */
}

.subscriber-logo-team {
  width: 60px;
  border-radius: 30px;
}

.subscriber-name {
  margin: 0;
  font-size: larger;
}

.user-mail {
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: smaller;
}
.card-content {
  padding: 25px;
  height: 200px;
}
.card-footer {
  margin-top: auto; /* Align footer to bottom */
  width: 100%;
  position: absolute;
  bottom: 0;
}

.card-footer button {
  width: 100%;
  height: 50px;
  background: rgba(234, 250, 246, 1);
  color: rgba(82, 219, 178, 1);
  border: none;
  padding: 8px 16px;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  font-size: medium;
}

.card-clicked {
  width: 60%;
}

.row {
  display: flex;
}

.column {
  flex: 1;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}
.row .column:first-child.visible {
  flex: 0 0 70%; /* 60% width for the first column */
}

.row .column:last-child.visible {
  flex: 0 0 30%;
  border: 1px solid #555;
  border-right: none;
  border-radius: 10px;
}

.cardmodal-usermodal {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
  margin: auto;
}

.cardmodal-usermodal button {
  background-color: transparent;
  border: none;
  margin: 5px;
  cursor: pointer;
  color: black;
}

.cardmodal-usermodal button.active {
  color: purple;
  background-color: rgba(244, 236, 251, 1);
  border-radius: 5px;
}
/*Modal*/
.user-modal {
  padding: 10px;
  position: relative;
}
.closebtn {
  background: none;
  border: none;
  cursor: pointer;
}
.modal-header-sub {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.closebtn {
  background: none;
  border: none;
  cursor: pointer;
}

.deletebtn-container {
  margin-left: auto;
}

.deletebtn {
  background: none;
  border: none;
  cursor: pointer;
}
.avatar-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
}
.user-details {
  display: flex;
  flex-direction: column;
}

.user-details > div {
  display: flex;
  align-items: center;
  padding: 10px;
}

.user-details > div img {
  margin-right: 5px;
}

.user-details > div p {
  margin: 0;
}
.line2 {
  width: 100%;
  margin: 5px 0;
}

/*Accordian*/
.accordion {
  padding-top: 20px;
}
.accordion-btn {
  color: #333;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: white;
  display: flex;
  align-items: center;
}
.accordion-icon {
  margin-right: 10px; /* Add some spacing between icon and text */
}

/* Style for accordion text */
.accordion-text {
  flex: 1;
  font-size: 15px;
  font-weight: bold;
}
/* .accordion-btn.active,
.accordion-btn:hover {
} */

.accordion-content {
  display: none;
  padding: 10px;
  position: relative;
  margin-left: 25px;
}
/* 
input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 40px;
} */

.accordion-content.active {
  display: block;
}
#checkbox2 {
  top: 35px;
}

#checkbox3 {
  top: 62px;
}
.acc-content {
  padding-bottom: 10px;
}
.accordion-arrow {
  top: 0;
  right: 0;
}
@media only screen and (min-width: 320px) and (max-width: 399px) {
  .four-cards-per-row .card1 {
    width: calc(100% - 25px);
    margin-right: 10px;
  }
  .three-cards-per-row .card1 {
    width: calc(27% - 25px);
    margin-right: 10px;
  }
  .search-field-team {
    margin-left: 20px; /* Decrease margin */
  }

  .search-input-team {
    margin-left: 10px; /* Decrease margin */
    padding: 10px 20px; /* Adjust padding */
    background-position: 5px center; /* Adjust background position */
    background-size: 15px; /* Adjust background size */
  }

  /* Adjusted styles for card container */
  .card-container-team {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .card-team {
    flex: 0 0 calc(50% - 10px); /* Adjust card width */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }

  /* Adjusted styles for modal */
  .cardmodal-usermodal {
    width: 90%; /* Adjust modal width */
  }

  /* Adjusted styles for accordion */
  .accordion-content {
    margin-left: 0; /* Remove left margin */
  }

  input[type="checkbox"] {
    right: 10px; /* Adjust checkbox position */
  }
}
@media only screen and (min-width: 400px) and (max-width: 767px) {
  .four-cards-per-row .card1 {
    width: calc(
      50% - 10px
    ); /* Make each card occupy 50% of the width with spacing */
    margin-right: 0; /* Remove the right margin */
  }
  .three-cards-per-row .card1 {
    width: calc(27% - 25px);
    margin-right: 10px;
  }
  .search-field-team {
    margin-left: 20px; /* Decrease margin */
  }

  .search-input-team {
    margin-left: 10px; /* Decrease margin */
    padding: 10px 20px; /* Adjust padding */
    background-position: 5px center; /* Adjust background position */
    background-size: 15px; /* Adjust background size */
  }

  /* Adjusted styles for card container */
  .card-container-team {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .card-team {
    flex: 0 0 calc(50% - 10px); /* Adjust card width */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }

  /* Adjusted styles for modal */
  .cardmodal-usermodal {
    width: 90%; /* Adjust modal width */
  }

  /* Adjusted styles for accordion */
  .accordion-content {
    margin-left: 0; /* Remove left margin */
  }

  input[type="checkbox"] {
    right: 10px; /* Adjust checkbox position */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .four-cards-per-row .card1 {
    width: calc(25% - 25px);
    margin-right: 10px;
  }
  .three-cards-per-row .card1 {
    width: calc(27% - 25px);
    margin-right: 10px;
  }
  .search-field-team {
    margin-left: 100px; /* Decrease margin */
  }

  .search-input-team {
    margin-left: 80px; /* Decrease margin */
  }

  /* Adjusted styles for card container */
  .card-container-team {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center align cards */
  }

  .card-team {
    flex: 0 0 calc(33% - 10px); /* Adjust card width */
    margin-right: 0; /* Remove right margin */
    margin-bottom: 20px; /* Add bottom margin for spacing */
  }

  /* Adjusted styles for modal */
  .cardmodal-usermodal {
    width: 80%; /* Adjust modal width */
  }

  .row .column:first-child.visible {
    flex: 0 0 100%; /* Full width for the first column */
  }

  .row .column:last-child.visible {
    flex: 0 0 100%; /* Full width for the second column */
    border: none; /* Remove border */
    border-top: 1px solid #555; /* Add top border */
    border-radius: 0; /* Remove border radius */
    margin-top: 20px; /* Add top margin for spacing */
  }

  /* Adjusted styles for accordion */
  .accordion-content {
    margin-left: 0; /* Remove left margin */
  }

  input[type="checkbox"] {
    right: 20px; /* Adjust checkbox position */
  }
}

body {
  font-family: "Nunito", sans-serif; /* Apply Nunito font to the entire document */
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px; /* This padding creates space around the cards */
}

.card {
  width: calc(25% - 45px);
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 10px;
  height: 120px;
  position: relative;
}

.card h2 {
  margin-top: 0;
}
.card span {
  font-size: 13px;
}
.subscriber-icon {
  position: absolute;
  top: 30%;
  right: 15px;
  transform: translateY(-50%);
  width: 60px;
  height: auto;
}
.percentage-container {
  display: flex;
  align-items: center;
}

.percentage {
  color: rgba(0, 182, 155, 1);
}

.container {
  display: flex;
  flex-direction: row;
  padding: 40px;
  margin-top: -70px;
}
.negative {
  color: red;
}
.card-chart {
  flex: 1;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  margin-right: 20px; /* Add margin */
  margin-bottom: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  height: 60%;
  margin-top: 2%;
}
.select-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.card-chart2 {
  flex: 1; /* Change to a flexible width */
  padding: 10px;
  border-radius: 5px;
  height: 57%;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.container-table {
  padding: 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-left: 40px;
  margin-right: 40px;
  position: relative;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-container {
  margin: 10px;
}
.bold-text {
  font-weight: bold;
}

.product-info {
  display: flex;
  align-items: flex-start;
}

.product-details {
  margin-left: 10px;
  text-align: left;
}

.product-image {
  width: 50px;
  height: 50px;
}
.product-details span {
  display: block;
  text-align: left;
}
.product-name-column {
  width: 500px;
}

.error-message-container {
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 10%;
}

/* For screen widths between 320px and 767px */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .card {
    width: calc(100% - 20px);
    margin-bottom: 15px;
  }

  .container {
    flex-direction: column; /* Stack cards vertically */
    padding: 10px; /* Adjust padding for spacing */
  }

  .card-chart {
    flex: 100%; /* Take full width */
    padding-right: 0; /* Remove right padding */
  }

  .card-chart2 {
    flex: 100%; /* Take full width */
    padding: 0; /* Remove padding */
  }

  .subscriber-icon {
    width: 40px; /* Adjust icon size */
  }
}

/* For screen widths between 768px and 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .card {
    width: calc(33.33% - 20px); /* Three cards per row with some spacing */
    margin-bottom: 15px;
  }

  .container {
    flex-direction: row; /* Revert to horizontal layout */
    padding: 20px; /* Reset padding */
  }

  .card-chart {
    flex: 60%; /* Adjust chart width */
  }

  .card-chart2 {
    flex: 40%; /* Adjust chart width */
  }

  .subscriber-icon {
    width: 40px; /* Adjust icon size */
  }
}
